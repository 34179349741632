import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M389.105621,543.133911 
	C360.259583,567.120056 320.467560,560.475647 301.475311,528.791321 
	C299.649963,525.746094 298.632446,522.450806 297.662170,519.076477 
	C294.535614,508.203857 285.951385,502.332794 274.725403,503.222198 
	C265.378906,503.962708 260.291321,509.366791 260.281067,518.594238 
	C260.273285,525.588562 260.574097,532.597107 260.221710,539.573303 
	C259.834167,547.245789 256.617615,553.487671 248.934418,556.273560 
	C242.206528,558.712952 236.154831,557.199707 231.039291,552.156372 
	C228.183258,549.340576 226.796570,546.055420 226.819382,541.898987 
	C226.947433,518.584839 226.869644,495.269623 226.917114,471.954926 
	C226.938416,461.493927 232.413635,454.293945 241.763275,452.206818 
	C249.239471,450.537903 256.577057,455.026428 261.213745,464.429962 
	C266.398193,463.282288 271.792023,462.558746 277.284302,462.594116 
	C304.237732,462.767853 324.779816,485.869385 327.810730,504.073212 
	C330.861359,522.395203 349.232697,529.745850 363.183624,519.053955 
	C366.908051,516.199524 369.310303,512.393921 370.523987,508.011963 
	C371.396667,504.861115 372.950897,503.704773 376.141449,503.748718 
	C385.298950,503.874756 394.460388,503.855164 403.618347,503.739471 
	C407.389252,503.691864 408.529907,504.933350 407.797394,508.846558 
	C405.293732,522.221375 399.340363,533.621948 389.105621,543.133911 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M948.880249,161.400391 
	C947.206360,168.058167 941.453308,171.002518 937.773865,175.607056 
	C937.248535,176.264420 936.175842,176.700485 935.513062,176.172867 
	C930.227051,171.964493 929.620422,177.420334 927.830627,179.966110 
	C926.145569,182.362976 925.906921,185.253967 926.259460,188.139008 
	C926.587708,190.825378 927.750488,192.417786 930.517639,190.535385 
	C933.820374,188.288620 936.033142,189.677963 937.247986,192.698273 
	C939.185181,197.514282 943.251587,201.036942 944.787842,206.127930 
	C945.642212,208.959473 945.197021,210.841263 942.645447,211.801331 
	C930.330566,216.434982 926.063721,227.555435 921.128662,238.157516 
	C920.562744,239.373138 920.169373,240.751038 918.660583,241.058395 
	C916.908325,241.415390 915.899963,240.265732 915.030945,238.969513 
	C914.302124,237.882355 913.952820,236.494934 912.612549,235.891205 
	C912.465820,235.825119 912.295898,235.810593 912.073059,235.756744 
	C909.709900,235.828125 909.897522,239.014725 906.848694,239.610153 
	C904.194824,234.342270 903.779724,228.500534 904.322510,222.678329 
	C905.613159,208.835144 904.283264,195.082520 903.179016,181.337692 
	C902.663025,174.916336 900.104919,168.826828 898.919434,162.448898 
	C897.611206,155.410812 897.483459,148.549438 898.937744,141.652069 
	C899.738037,137.856186 899.381836,133.992340 899.946167,130.273758 
	C901.264832,121.584785 896.059143,117.528938 889.559570,114.307068 
	C882.410706,110.763374 877.090698,104.642754 869.916870,101.028038 
	C865.762207,98.934578 866.341675,94.246468 867.709229,90.261215 
	C869.014954,86.456352 871.659241,83.788948 875.863464,84.199310 
	C881.819275,84.780624 887.779602,84.912514 893.737000,85.260437 
	C897.235168,85.464722 900.120728,87.626839 903.240173,89.209160 
	C909.674744,92.473015 915.396057,97.039955 922.465698,99.207794 
	C930.088501,101.545258 937.679565,106.429344 937.018005,117.368591 
	C936.532715,125.392563 937.468201,132.951675 944.548828,138.511230 
	C946.519165,140.058273 947.873962,142.850433 947.920654,145.578873 
	C948.009033,150.735550 949.561890,155.787567 948.880249,161.400391 
M908.161499,150.873734 
	C908.130493,152.862030 907.882141,154.876221 908.115051,156.833115 
	C908.567444,160.633926 909.986206,163.905563 914.175415,164.972305 
	C917.540833,165.829269 920.916138,166.656052 924.309448,167.392822 
	C927.482788,168.081818 929.183594,166.976913 928.617920,163.479126 
	C927.666809,157.598236 926.696594,151.720444 925.754089,145.838196 
	C925.498779,144.244843 924.701721,143.140244 923.235779,142.401855 
	C916.796082,139.158249 909.576111,142.799408 908.161499,150.873734 
M925.846558,219.086472 
	C926.084656,217.581879 926.129761,216.042374 925.353760,214.713715 
	C924.192200,212.724823 922.375793,211.663528 920.473755,213.472122 
	C918.451172,215.395416 918.270630,218.167938 919.940918,220.284454 
	C921.661316,222.464249 923.928040,222.493668 925.846558,219.086472 
M918.695129,118.015244 
	C918.007629,114.665382 915.559509,116.179199 913.715088,116.309654 
	C912.838074,116.371681 912.232361,117.317558 912.648254,118.140320 
	C913.825562,120.469345 915.741943,119.062149 917.395447,118.869980 
	C917.703491,118.834183 917.990234,118.615517 918.695129,118.015244 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M687.289917,192.261169 
	C684.973877,195.083664 682.228394,196.989731 679.290344,194.817551 
	C670.052246,187.987564 659.944885,187.485107 648.900146,188.277664 
	C640.384583,188.888733 638.593994,185.962326 637.930786,177.130264 
	C637.519592,171.654221 637.298889,166.161102 636.762207,160.698273 
	C636.414307,157.157547 636.265320,153.432724 632.430847,150.778275 
	C637.472290,148.900055 641.795776,149.720093 645.461731,151.858856 
	C655.260620,157.575592 666.317810,160.518387 676.090271,166.314636 
	C680.146729,168.720627 685.158936,169.145355 689.437866,171.604233 
	C702.380859,179.041931 716.125305,184.887604 729.954285,190.423981 
	C737.168579,193.312195 744.717529,195.506927 752.167236,197.719788 
	C761.365662,200.452042 769.008667,206.401581 778.196838,208.984650 
	C793.461182,213.275879 806.974792,221.837906 821.729797,227.228973 
	C830.664307,230.493439 839.287109,234.299744 847.941956,238.121933 
	C850.290955,239.159332 853.027893,239.803223 854.599243,243.480133 
	C849.152649,243.683441 844.685364,241.790131 840.073364,240.487488 
	C828.909302,237.334213 818.477722,231.626373 806.684326,230.711929 
	C801.380127,230.300659 796.047424,230.196548 790.724915,230.096329 
	C782.900208,229.948990 776.529846,227.032028 771.783386,220.696152 
	C768.864014,216.799225 764.719421,215.266876 760.022583,215.166672 
	C749.741272,214.947296 740.800903,211.024734 733.138245,204.579453 
	C722.940796,196.002213 711.030029,191.198227 698.550842,187.421616 
	C693.740784,185.965912 690.089966,187.771774 687.289917,192.261169 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M768.825684,516.682129 
	C774.107056,513.058655 779.547607,513.730713 784.925354,515.013611 
	C787.267395,515.572327 788.258545,517.690552 788.272278,520.051697 
	C788.340271,531.704163 788.378906,543.356934 788.389099,555.009583 
	C788.392700,559.103699 785.501465,559.204407 782.485901,559.234985 
	C779.102295,559.269287 775.887756,559.137634 775.850891,554.574768 
	C775.795776,547.756042 775.836548,540.936584 774.854614,533.762695 
	C771.987610,539.931641 769.098145,546.090332 766.260925,552.273010 
	C764.108765,556.962708 760.775024,559.352661 755.340820,559.330322 
	C750.333557,559.309692 747.146423,557.313782 745.135315,552.901672 
	C742.201599,546.465637 739.191223,540.064514 735.256287,533.847534 
	C735.256287,540.265442 735.082825,546.689636 735.312134,553.099365 
	C735.490540,558.088074 733.048584,559.311340 728.564880,559.313354 
	C723.920166,559.315491 722.471741,557.306274 722.552185,552.923706 
	C722.741577,542.605835 722.714050,532.281677 722.606995,521.961792 
	C722.555847,517.027588 724.711609,514.771301 729.652649,514.289429 
	C739.715454,513.307983 741.599121,514.179749 745.771545,523.358459 
	C748.042847,528.355225 750.133545,533.434143 752.406738,538.429993 
	C753.270447,540.328064 753.657043,542.575317 756.142517,544.119934 
	C760.328674,534.981567 764.451721,525.981079 768.825684,516.682129 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M474.475952,517.253906 
	C479.206207,513.942505 484.398163,514.554321 489.299774,514.286255 
	C496.438293,513.895813 503.610870,514.164246 510.767303,514.051636 
	C515.172729,513.982300 516.412292,516.239685 516.381897,520.335327 
	C516.351135,524.468628 515.043518,526.529663 510.615326,526.422852 
	C503.128296,526.242310 495.633881,526.319397 488.143127,526.370178 
	C485.858673,526.385620 483.373077,526.297668 482.565796,529.298157 
	C483.795044,531.475403 485.846863,530.670227 487.516693,530.690918 
	C495.173248,530.785889 502.832336,530.798157 510.488983,530.708069 
	C514.494507,530.660950 516.251038,532.431274 516.170654,536.442200 
	C516.092224,540.351624 514.438049,542.246155 510.401825,542.192566 
	C502.911987,542.093079 495.419708,542.148926 487.928711,542.190308 
	C486.166168,542.200012 484.281799,541.821533 482.572144,543.188049 
	C482.732666,546.555908 485.066467,546.722534 487.442719,546.733459 
	C494.933807,546.768066 502.427338,546.880371 509.915375,546.732422 
	C514.183716,546.648071 516.369751,547.959961 516.393250,552.694885 
	C516.416443,557.367310 514.814514,559.385803 510.002899,559.255737 
	C501.851624,559.035400 493.686646,559.337280 485.534271,559.138367 
	C474.388397,558.866272 468.993530,553.475159 468.840057,542.375366 
	C468.762177,536.746765 468.193420,531.086243 469.503784,525.467102 
	C470.253967,522.250244 471.503845,519.499084 474.475952,517.253906 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M449.241821,477.946289 
	C449.241180,479.767151 449.249146,481.099152 449.236908,482.430939 
	C449.176331,489.033295 447.291351,490.611115 440.763580,489.928589 
	C437.695679,489.607849 436.175507,487.976227 436.132416,485.109100 
	C435.957397,473.462463 435.737610,461.811005 436.771149,450.184692 
	C436.969238,447.955963 438.268585,446.540985 440.171478,445.612122 
	C445.197845,443.158478 453.824005,445.003265 457.365051,449.480469 
	C462.526917,456.006989 467.514374,462.671265 472.620422,469.242401 
	C473.593964,470.495300 474.236206,472.170532 476.825378,472.688324 
	C476.825378,465.760620 476.798431,459.014618 476.832916,452.268982 
	C476.867859,445.440033 478.046967,444.445740 484.719330,444.820251 
	C488.014557,445.005188 489.513550,446.400208 489.532257,449.756073 
	C489.586121,459.392761 489.952209,469.030701 489.836914,478.663910 
	C489.789307,482.640625 490.440582,487.368042 485.279968,489.251312 
	C479.296783,491.434845 472.341431,489.938263 468.846741,485.500519 
	C463.492462,478.701385 458.347351,471.737549 453.111389,464.845215 
	C452.323120,463.807587 451.822266,462.482727 449.677399,461.862579 
	C448.760498,467.119354 449.456085,472.322174 449.241821,477.946289 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M579.281982,527.884033 
	C578.225037,531.513000 580.843567,530.660522 582.353943,530.681030 
	C590.177368,530.787598 598.002991,530.792908 605.827271,530.742981 
	C609.550110,530.719238 612.346008,531.272217 612.330566,536.084839 
	C612.315918,540.652100 610.369507,542.307556 605.961060,542.203735 
	C598.640015,542.031311 591.311340,542.123718 583.986816,542.196777 
	C582.243408,542.214172 580.304321,541.635925 578.702820,543.301086 
	C578.836243,546.526306 581.141602,546.721863 583.554321,546.729980 
	C591.212463,546.755554 598.873108,546.887512 606.527893,546.734070 
	C610.985718,546.644592 612.961121,548.596558 612.969116,552.955444 
	C612.977112,557.285095 611.086060,559.316528 606.591736,559.230652 
	C598.104187,559.068359 589.606934,559.350525 581.122253,559.124939 
	C570.904114,558.853271 566.109558,553.687988 565.523987,543.586853 
	C565.205200,538.087036 565.105957,532.646301 565.665833,527.166992 
	C566.361023,520.363220 570.480957,515.526672 577.292969,514.877441 
	C587.233032,513.930176 597.221130,513.850281 607.217102,514.250427 
	C611.949646,514.439819 613.065613,517.013733 612.943909,521.088684 
	C612.817932,525.305542 610.410095,526.363220 606.690918,526.311890 
	C599.533752,526.212952 592.373901,526.298218 585.215271,526.326599 
	C583.226807,526.334473 581.184143,526.167297 579.281982,527.884033 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M670.131470,556.023499 
	C663.451233,549.409302 662.101562,526.506409 667.712341,519.538696 
	C670.278503,516.351990 673.769287,515.024841 677.737854,514.651123 
	C687.216736,513.758606 696.697571,514.015076 706.165710,514.699646 
	C713.846191,515.254883 718.753601,520.125793 719.176331,527.896118 
	C719.492371,533.705933 719.479980,539.559326 719.179443,545.370789 
	C718.769104,553.304749 713.671692,558.585632 705.329773,559.018738 
	C696.365479,559.484314 687.354919,559.188477 678.368347,559.020508 
	C675.529053,558.967468 672.857666,557.819580 670.131470,556.023499 
M678.706482,528.260376 
	C676.255188,533.155884 677.411743,538.318176 677.737305,543.378906 
	C677.830139,544.820618 679.052246,546.336975 680.650146,546.444031 
	C688.098267,546.943176 695.629883,547.430603 702.985413,546.273193 
	C707.130249,545.620972 706.146912,541.202637 705.879333,538.287231 
	C705.516968,534.338379 708.416565,528.104736 702.784973,527.061035 
	C695.149414,525.645935 687.037903,525.032898 678.706482,528.260376 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M338.774170,442.468872 
	C371.865784,435.834534 402.787048,458.022186 407.043701,490.971619 
	C407.534241,494.768738 406.100372,496.023132 402.458221,496.117584 
	C394.284393,496.329651 394.287323,496.442535 392.807220,488.570312 
	C389.228821,469.537628 371.022400,454.542999 351.577606,454.114594 
	C341.947266,453.902405 333.011841,455.633331 325.112793,461.229706 
	C321.493652,463.793793 320.184021,461.474762 318.575592,458.946686 
	C316.847900,456.231232 314.628326,453.703674 318.800568,451.069855 
	C324.896179,447.221771 331.229919,444.000549 338.774170,442.468872 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M819.528076,32.563332 
	C817.263123,24.705910 820.186890,20.704390 827.964722,20.716564 
	C832.950439,20.724367 837.940247,20.887163 842.920349,21.136763 
	C854.216919,21.702940 869.544373,34.761505 868.247192,49.565277 
	C868.001343,52.370926 868.350830,55.225693 868.163391,58.040340 
	C867.959106,61.108166 866.214966,63.300514 863.043396,62.854748 
	C858.755615,62.252090 854.201416,61.086559 851.590515,57.465904 
	C848.766113,53.549229 845.080505,51.456234 840.864075,49.791264 
	C835.925110,47.841000 831.249878,45.308743 826.136719,43.700485 
	C821.101685,42.116795 819.877258,37.709091 819.528076,32.563332 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M630.141602,546.036987 
	C638.967712,547.224731 647.464661,546.493347 655.946594,546.682373 
	C661.766785,546.812134 663.015808,547.964478 663.093506,553.038086 
	C663.167603,557.874817 660.747803,559.380981 656.221191,559.272766 
	C647.893982,559.073608 639.556519,559.329651 631.227539,559.172791 
	C620.451416,558.969910 614.917542,553.451050 614.827026,542.667175 
	C614.778320,536.865234 614.036499,531.003723 615.538452,525.253357 
	C617.241943,518.731323 621.090576,515.085693 627.822632,514.762817 
	C637.781677,514.285034 647.765015,514.307434 657.738220,514.130615 
	C661.942871,514.056091 663.129333,516.545898 663.079712,520.274109 
	C663.028564,524.114075 662.076660,526.637451 657.397766,526.480591 
	C650.088928,526.235718 642.763855,526.343933 635.448425,526.466736 
	C629.212891,526.571350 627.995056,527.688293 628.224121,533.780823 
	C628.375244,537.802002 626.731262,542.140686 630.141602,546.036987 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M541.071899,452.727661 
	C540.464844,456.881927 537.749451,457.292175 534.718262,457.285889 
	C527.393738,457.270691 520.067810,457.376556 512.745300,457.257812 
	C508.187256,457.183899 506.153839,458.995148 506.062927,463.752960 
	C505.798035,477.613373 505.670929,477.619263 519.396484,477.634064 
	C524.889709,477.639984 530.386047,477.705017 535.875061,477.543701 
	C539.454407,477.438507 541.154602,478.945740 541.161255,482.504913 
	C541.167725,485.936859 541.359741,489.712860 536.566772,489.799133 
	C526.757263,489.975800 516.911987,490.586670 507.138397,489.508606 
	C497.866699,488.485901 494.605713,485.284454 493.261017,476.033020 
	C492.328003,469.613861 492.415497,463.129883 493.558044,456.665558 
	C494.648834,450.494385 498.135498,446.635468 504.196869,445.667511 
	C515.099976,443.926300 526.120300,444.759247 537.080505,445.040222 
	C541.486572,445.153168 541.114502,449.104797 541.071899,452.727661 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M428.111694,514.089294 
	C439.897980,513.793762 451.188782,514.787903 462.505066,514.186279 
	C468.803772,513.851379 470.103058,515.746765 469.475250,521.937256 
	C469.142578,525.217896 467.546204,526.577454 464.334198,526.705200 
	C450.098389,527.271179 450.102844,527.305481 450.094971,541.560669 
	C450.092773,545.553284 450.009369,549.546692 450.065491,553.538330 
	C450.137909,558.692017 446.978241,559.312500 442.794678,559.282104 
	C438.696625,559.252380 436.583588,557.937012 436.681305,553.505798 
	C436.842651,546.189575 436.549591,538.862061 436.765625,531.548767 
	C436.870758,527.990112 435.231049,526.751160 432.089050,526.661621 
	C428.769562,526.566956 425.444672,526.388062 422.129425,526.487915 
	C418.102142,526.609192 416.958618,524.351196 416.877197,520.857300 
	C416.796356,517.390564 417.288513,514.416321 421.645447,514.205261 
	C423.636627,514.108704 425.633118,514.122009 428.111694,514.089294 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M426.012451,969.434509 
	C422.693695,976.224365 420.891388,976.971252 416.474335,973.262085 
	C419.123413,971.241089 420.953674,968.889160 418.779327,965.442261 
	C418.129303,964.411743 417.600586,963.345703 416.201447,963.517883 
	C414.785767,963.692078 414.402374,964.823425 414.233063,966.043579 
	C414.187408,966.372742 414.199982,966.709961 414.154510,967.039185 
	C413.917603,968.754211 414.555847,971.073547 411.944153,971.393677 
	C409.192810,971.731018 409.092194,969.200439 408.462402,967.244385 
	C404.608215,973.096436 400.250183,973.037598 394.933350,967.311829 
	C390.256409,972.386108 390.256409,972.386108 384.837860,967.471008 
	C382.174286,971.536377 378.060272,972.876465 374.620178,970.533020 
	C371.554260,968.444458 371.116882,965.470337 372.599060,962.162537 
	C373.165131,960.899170 374.093140,959.974304 375.338959,959.369995 
	C378.971436,957.608093 387.281586,959.763306 389.523071,963.048279 
	C395.898804,957.838257 401.899689,957.358704 407.796021,961.588135 
	C412.803223,957.050415 418.671326,957.548828 423.594208,963.302124 
	C425.348389,958.986328 428.966339,958.732117 432.805542,958.818665 
	C434.489838,958.856628 436.104889,958.421753 437.164948,956.847656 
	C437.943756,955.691223 438.993622,954.662903 440.473541,955.265930 
	C441.908997,955.850830 441.943207,957.398926 441.992188,958.718384 
	C442.071503,960.855896 442.013550,962.998474 442.642029,965.605591 
	C444.198944,961.943787 444.777924,956.889343 450.233643,958.911987 
	C453.852478,960.253662 459.120911,956.152161 460.542786,962.344360 
	C463.467529,961.974915 464.036591,957.287659 466.696808,959.411560 
	C469.282562,961.476013 466.165558,963.843567 465.503998,966.041870 
	C465.077942,967.457520 464.197327,968.731750 463.569916,970.092163 
	C460.612885,976.503662 459.332733,976.572876 453.334991,973.039612 
	C450.039368,971.098145 445.762146,970.715698 441.917755,971.026001 
	C437.095215,971.415283 432.401611,972.681335 428.539978,968.395813 
	C427.680664,967.442261 426.730469,968.149109 426.012451,969.434509 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M880.651123,195.285126 
	C880.292603,187.831665 880.023315,180.843582 879.560669,173.868347 
	C879.301575,169.961624 881.203918,168.641006 884.694458,169.232117 
	C887.640381,169.731003 890.563232,170.449753 893.433838,171.285507 
	C896.040771,172.044495 896.277344,174.353516 896.197693,176.571426 
	C896.018921,181.554413 896.300171,186.548630 895.253113,191.504288 
	C892.721436,203.486008 894.481750,215.363968 896.363403,227.234268 
	C896.997192,231.232758 899.300232,236.246490 895.091980,238.796814 
	C891.102783,241.214371 887.539124,236.649567 883.823181,235.143845 
	C881.667175,234.270203 880.848572,231.949844 881.069275,229.661346 
	C882.161255,218.339447 880.551758,207.059280 880.651123,195.285126 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M564.425720,549.515503 
	C564.610413,558.870300 564.331238,559.172119 555.461670,559.196594 
	C548.630859,559.215454 541.794739,559.351990 534.970154,559.140808 
	C524.373657,558.812927 519.092102,553.438477 518.890564,542.869385 
	C518.744507,535.208252 519.034485,527.536072 518.776672,519.880737 
	C518.608215,514.880310 521.442627,514.179565 525.398254,514.023376 
	C529.667236,513.854858 532.077759,515.221741 531.986877,519.909973 
	C531.864197,526.238464 531.915100,532.572021 531.990051,538.902222 
	C532.073303,545.926575 532.848145,546.669678 539.730774,546.695190 
	C546.061890,546.718689 552.393066,546.719727 558.724243,546.709473 
	C560.945679,546.705933 563.117004,546.755005 564.425720,549.515503 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M351.852844,455.658417 
	C372.031677,457.221008 388.030670,471.442963 391.892700,490.822021 
	C392.351501,493.124115 392.505859,495.519653 389.473541,495.743347 
	C386.571838,495.957397 382.900360,497.526703 381.363922,493.218079 
	C380.473602,490.721283 380.093597,488.018127 379.020844,485.614532 
	C371.169586,468.023376 350.441956,461.307312 333.139191,470.643890 
	C330.903656,471.850220 328.931061,473.982391 326.377777,470.603027 
	C322.382935,465.315704 322.426453,464.022003 328.325653,460.850922 
	C335.514862,456.986420 343.180695,455.100220 351.852844,455.658417 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M656.265747,959.183350 
	C658.305359,960.174194 659.515320,961.515015 660.525940,963.514465 
	C662.639771,959.656128 665.755798,958.316772 669.763672,958.800903 
	C670.562195,958.897339 671.696106,958.974976 672.175110,958.526672 
	C676.302979,954.664001 678.554443,958.007446 681.210388,960.992981 
	C685.507874,957.822693 689.750549,958.037842 693.846008,962.177429 
	C696.501526,958.850586 700.959045,958.992188 703.847412,955.971802 
	C705.636536,954.100891 707.242126,955.412781 707.353882,957.860657 
	C707.473694,960.487976 707.380859,963.124939 707.380859,965.534363 
	C711.407227,967.806335 715.251831,966.422913 718.958008,966.660645 
	C720.758850,966.776123 722.522278,966.996948 722.335938,969.277100 
	C722.176575,971.227051 720.499451,971.261230 718.915894,971.270081 
	C712.671326,971.305115 706.425171,971.328247 700.183655,971.502441 
	C697.520935,971.576782 695.914307,969.974915 693.861145,968.029663 
	C691.816223,972.085632 688.547729,971.797302 684.649597,971.250244 
	C680.047119,970.604126 675.233032,970.661377 670.608154,971.220154 
	C666.468750,971.720398 663.019714,971.498779 660.562866,967.678345 
	C657.339050,972.677429 653.562744,972.853882 648.054260,968.532837 
	C645.409180,972.276367 642.828979,972.478455 642.115540,968.992371 
	C641.762573,967.267517 642.895691,965.340271 641.208801,963.479553 
	C637.968079,963.776794 638.459167,966.398865 638.291199,968.537415 
	C638.183044,969.914062 638.123718,971.434570 636.373108,971.503601 
	C634.422791,971.580505 633.818604,970.052734 633.954102,968.436401 
	C634.220459,965.259644 632.500854,960.804199 635.962585,959.339661 
	C639.900574,957.673767 644.599915,957.959290 647.266724,963.381409 
	C649.654297,960.225037 652.085083,957.974792 656.265747,959.183350 
M672.523987,965.624817 
	C671.427002,962.272339 668.797913,963.323303 666.603088,963.773621 
	C665.644531,963.970276 665.262817,965.285095 665.936707,966.261536 
	C667.545227,968.592224 669.478271,966.935120 671.289978,966.557312 
	C671.599792,966.492737 671.877014,966.271545 672.523987,965.624817 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M326.013824,971.298401 
	C323.271423,971.567871 323.107819,969.895203 322.966522,968.130066 
	C322.841003,966.561646 323.486511,964.720703 321.536652,963.655212 
	C318.652222,964.283875 319.562042,966.643921 319.372467,968.464355 
	C319.232178,969.811340 319.261414,971.331116 317.437683,971.406616 
	C315.445709,971.489136 315.071869,969.981689 314.897552,968.382019 
	C314.724579,966.794434 315.351105,965.010864 313.667542,963.557007 
	C310.781555,963.959961 311.401489,966.217529 311.540833,968.036011 
	C311.741669,970.656128 310.699493,971.631287 308.076447,971.519836 
	C303.682159,971.333069 299.277283,971.327454 294.877808,971.363281 
	C293.003113,971.378601 291.373840,971.196045 291.547485,968.836487 
	C291.723297,966.446838 293.506073,966.709961 295.266663,966.749329 
	C297.579468,966.800964 299.909088,966.656677 302.212738,966.416687 
	C304.823883,966.144714 307.169281,965.468994 307.137787,962.051086 
	C307.125336,960.697388 307.504761,959.370300 309.119110,958.959778 
	C312.896423,957.999329 316.642181,959.763733 320.481659,958.957886 
	C325.113190,957.985840 327.420441,960.950806 328.896301,965.375305 
	C329.375671,961.799011 329.023376,958.414734 333.831268,958.692505 
	C338.455750,958.959595 340.219025,961.539612 340.966461,965.692200 
	C340.966461,963.435547 340.862213,961.172485 341.001282,958.924438 
	C341.102539,957.288513 341.239594,955.319580 343.525208,955.290527 
	C345.530151,955.265076 345.631287,957.060608 345.963257,958.512512 
	C346.108124,959.146118 346.282806,959.772888 346.563965,960.872070 
	C350.787354,957.447083 355.556061,959.220581 360.003021,959.021362 
	C363.751312,958.853455 366.105652,961.717407 365.642303,965.719727 
	C365.205841,969.489502 363.814758,972.686218 358.918365,971.450439 
	C355.281738,970.532715 351.410461,972.439941 347.753876,970.002258 
	C346.041534,968.860657 344.075562,971.438721 341.621613,971.641846 
	C336.427826,972.071777 331.420807,970.208679 326.013824,971.298401 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M629.060547,118.117874 
	C629.415039,114.359970 629.707886,111.047714 630.087036,107.745369 
	C630.275879,106.100212 630.372253,104.376465 630.957092,102.860641 
	C631.834778,100.586205 632.635803,97.662605 635.565857,97.570343 
	C638.070435,97.491486 639.626648,99.768623 640.653381,101.868820 
	C642.061218,104.748787 643.945251,107.264748 645.905518,109.743507 
	C646.612244,110.637100 647.559448,112.100563 648.332886,112.060745 
	C654.440369,111.746315 654.111694,116.483887 654.732178,120.181465 
	C655.418396,124.270729 655.613098,128.422546 656.695496,132.478241 
	C657.465515,135.363312 656.179749,137.009827 652.935303,136.479233 
	C650.135620,136.021347 647.330322,135.603119 644.987793,133.780746 
	C640.964539,130.650818 637.389343,127.138718 631.801941,126.240318 
	C628.142822,125.651978 629.045288,121.509491 629.060547,118.117874 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M351.419891,517.771790 
	C343.575958,518.221558 338.133331,514.925903 335.677246,508.599487 
	C333.221588,502.274017 335.139984,495.875671 340.848785,491.351227 
	C345.364349,487.772522 352.151306,487.546875 357.107178,491.071075 
	C361.984619,494.539490 364.133148,499.408630 363.219788,505.351166 
	C362.206696,511.943115 358.261444,516.008118 351.419891,517.771790 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M430.276245,445.314880 
	C432.988525,447.007782 432.934021,449.441986 432.945312,451.741058 
	C432.996613,462.209473 433.030640,472.678986 432.931427,483.146790 
	C432.877502,488.834869 431.753082,489.489777 424.946594,489.703064 
	C420.119781,489.854279 419.611816,487.046814 419.646637,483.289001 
	C419.735901,473.651825 419.672028,464.013306 419.675110,454.375305 
	C419.678162,444.749817 420.259674,444.226013 430.276245,445.314880 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M363.236267,485.752289 
	C355.644562,479.360046 347.736755,478.350311 339.576935,483.502228 
	C335.180542,486.277985 334.548706,482.403992 332.923553,480.322052 
	C331.194153,478.106537 329.982178,475.879608 333.466522,473.806030 
	C350.554260,463.636841 372.298706,472.115631 378.211365,491.821289 
	C378.684265,493.397308 379.072510,495.562134 376.678314,495.741241 
	C373.735168,495.961365 370.143799,497.378296 368.301392,493.513641 
	C367.003937,490.791992 365.613678,488.179626 363.236267,485.752289 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M747.635254,43.999187 
	C752.597656,45.120987 757.207764,45.987637 760.452759,49.845692 
	C763.318787,53.253136 763.875854,57.163734 760.788757,60.213081 
	C757.467651,63.493660 756.240051,66.534203 759.016724,70.840103 
	C755.525391,71.947990 753.568909,70.137100 751.687317,68.341682 
	C747.008972,63.877708 742.248108,59.485939 737.810852,54.790585 
	C735.968567,52.841129 733.810364,52.017265 731.506409,51.071911 
	C729.856323,50.394848 728.268921,49.347301 727.777283,47.298580 
	C733.669556,42.649975 740.483398,43.591167 747.635254,43.999187 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M576.000732,972.000854 
	C571.050720,972.223145 566.128845,970.891663 561.157898,971.791321 
	C559.072144,972.168884 557.932983,970.374878 556.391663,969.319214 
	C555.557861,969.867310 554.801941,970.625061 553.897217,970.909424 
	C551.307983,971.723328 547.977539,972.385132 546.391113,970.306763 
	C544.641541,968.014709 545.029724,964.385986 545.840759,961.572144 
	C546.645020,958.782104 549.529663,958.602661 552.144958,958.834167 
	C553.447632,958.949402 554.938049,959.668091 555.987183,958.613770 
	C559.764282,954.817383 561.624756,957.725403 563.751160,960.568970 
	C562.810364,962.692993 559.174072,964.510986 563.084229,967.190552 
	C564.794556,966.309143 566.366943,964.978943 565.795105,963.101562 
	C564.547791,959.006470 567.478210,958.274658 569.725647,958.940796 
	C573.901123,960.178406 579.845154,956.152954 581.919128,962.921265 
	C582.550049,962.848999 582.911621,962.902161 583.169373,962.762878 
	C584.994202,961.777466 585.268494,957.436768 588.208679,959.484436 
	C590.444397,961.041626 587.709656,963.341492 587.050903,965.217834 
	C586.118958,967.872620 584.886658,970.441467 583.573120,972.936768 
	C582.457336,975.056458 580.423706,975.886047 578.099915,975.640320 
	C576.389404,975.459534 575.366089,974.475342 576.165405,972.114929 
	C580.876770,970.232849 579.600830,968.188171 577.313049,965.663452 
	C575.581909,967.773804 576.625671,970.052795 576.000732,972.000854 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M477.337921,958.883667 
	C483.782043,961.967346 490.645782,956.731323 496.609619,960.672668 
	C505.014587,955.494141 502.261078,963.831787 503.987427,966.750732 
	C504.061127,966.875427 504.260498,966.925781 504.671722,967.173950 
	C507.636353,966.597900 506.574066,963.988525 506.826569,962.123291 
	C507.043518,960.520508 507.362244,958.981384 509.286346,959.004333 
	C511.180481,959.026855 511.720825,960.517822 511.681183,962.159668 
	C511.629181,964.314575 511.600159,966.484558 511.317657,968.615723 
	C510.977570,971.181274 505.801544,972.938721 502.056183,971.075378 
	C499.272858,969.690491 497.230530,969.070740 494.499084,970.951538 
	C493.445404,971.677063 491.510803,971.605774 490.127441,971.273560 
	C486.845764,970.485352 483.746582,970.494202 480.395020,971.208618 
	C477.066467,971.918030 473.549988,971.470215 472.513153,967.153625 
	C471.553467,963.158081 472.944885,960.325134 477.337921,958.883667 
M483.034363,966.158936 
	C481.656525,962.526611 480.366974,962.412354 477.636322,965.734375 
	C478.982452,967.641724 480.783508,966.786682 483.034363,966.158936 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M844.217896,180.141205 
	C844.309875,192.337570 845.777710,204.062820 844.358765,216.197922 
	C839.800476,215.421097 839.238159,212.005768 839.084412,208.880661 
	C838.758240,202.250046 838.646362,195.589645 838.869934,188.955734 
	C839.199768,179.170334 837.743896,169.391861 838.888428,159.615509 
	C839.153320,157.353333 839.394348,155.078003 841.666992,151.771072 
	C844.662354,161.797943 842.182739,170.998215 844.217896,180.141205 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M673.592285,138.258591 
	C672.618042,140.837402 672.649841,144.570312 669.207825,143.536392 
	C665.915283,142.547363 663.942383,139.520813 664.499573,135.686020 
	C665.813904,126.640091 667.141235,117.594612 668.625244,108.575592 
	C669.071045,105.866180 668.865479,102.928482 671.233276,99.612335 
	C673.517578,112.848198 673.166748,125.383392 673.592285,138.258591 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M669.018860,76.990677 
	C665.812439,70.969704 665.200134,64.819153 667.722229,58.668152 
	C670.033203,53.032150 675.977539,52.383896 680.650208,51.470104 
	C685.481323,50.525333 690.868286,51.516617 695.834351,53.022266 
	C696.733032,53.294746 697.751831,53.700855 697.882996,55.303757 
	C696.105164,56.721146 693.875183,56.475266 691.938721,55.828072 
	C682.720520,52.747189 678.578552,57.926159 674.916870,64.597130 
	C672.708008,68.621094 673.045532,73.749191 669.018860,76.990677 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M530.775879,965.163330 
	C531.149780,967.555908 532.809753,968.214661 534.455261,967.778137 
	C536.296692,967.289795 538.282593,965.309875 539.508301,968.055237 
	C540.561157,970.413635 539.147339,972.475159 536.936340,974.307251 
	C533.409302,970.932007 529.083191,970.663818 524.568604,971.413574 
	C522.295593,971.790955 521.112793,970.952393 520.874023,968.607239 
	C520.700378,966.901428 521.509766,964.873230 519.462341,963.590332 
	C516.342896,964.321411 517.537598,966.985229 517.155701,968.871399 
	C516.862427,970.319824 516.463501,971.512939 514.714233,971.350769 
	C513.263000,971.216309 512.870728,970.114075 512.825928,968.855652 
	C512.790527,967.859009 512.816650,966.859985 512.819519,965.862061 
	C512.840271,958.652283 513.719238,957.809143 520.971741,958.897827 
	C523.208008,959.233521 525.058899,959.619629 526.827454,957.628540 
	C528.999146,955.183655 530.697571,957.367981 532.301025,958.677002 
	C534.291504,960.302002 533.535522,961.786438 531.915588,963.210876 
	C531.440674,963.628540 531.190063,964.301270 530.775879,965.163330 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M619.152466,966.706055 
	C618.193542,970.694580 615.293884,972.248291 612.289795,971.575684 
	C608.471985,970.720886 604.907898,971.143494 601.168030,971.396790 
	C596.844238,971.689636 594.252197,968.647949 594.680542,964.555603 
	C595.086060,960.681519 598.729980,957.763184 602.572998,959.059753 
	C605.778442,960.141235 608.444702,959.878601 611.593323,959.037903 
	C615.977905,957.867126 618.813110,960.877869 619.152466,966.706055 
M602.350769,963.321655 
	C600.470703,963.354126 598.588013,964.257568 599.723511,966.109680 
	C601.386780,968.822510 603.490356,966.749084 605.310974,965.973206 
	C605.244324,964.115601 604.005920,963.917664 602.350769,963.321655 
M611.487000,963.843506 
	C610.200439,965.188293 609.909668,966.486389 611.980957,967.230103 
	C612.999084,967.595581 614.082947,966.994446 614.391846,965.992676 
	C615.009033,963.990967 614.022949,963.220520 611.487000,963.843506 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M785.586853,53.018284 
	C782.087952,52.243317 782.360291,50.151829 783.239441,47.933727 
	C784.458130,44.858814 789.960693,41.573544 792.526978,42.642040 
	C794.742737,43.564636 797.631714,44.421646 797.617615,47.470303 
	C797.605896,49.994671 794.943604,50.645401 793.202515,51.800312 
	C791.023010,53.246067 788.509155,53.354111 785.586853,53.018284 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M624.410400,966.529785 
	C624.016113,968.662048 624.999146,971.521729 621.841248,971.224060 
	C619.282959,970.982971 619.883118,968.431396 620.017822,966.793274 
	C620.232178,964.184509 618.380066,960.554626 621.806030,959.275574 
	C624.415039,958.301636 627.748047,957.918701 630.382874,959.902954 
	C633.429565,962.197388 633.272278,965.621277 633.062012,968.966248 
	C632.965576,970.500732 632.156067,971.470581 630.564026,971.447571 
	C628.975220,971.424561 628.299927,970.413269 628.081604,968.903564 
	C627.833191,967.185852 628.562378,965.223083 626.936035,963.795105 
	C625.031799,963.489380 624.779053,964.863464 624.410400,966.529785 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M844.079956,62.998688 
	C845.390930,63.746853 846.428711,64.245956 847.452026,64.773148 
	C848.882935,65.510391 849.790283,66.637482 849.689697,68.304688 
	C849.595276,69.870384 848.391602,70.409973 847.112854,70.942429 
	C845.464966,71.628555 843.890015,72.489761 842.155090,73.338425 
	C839.745361,72.111832 839.385132,69.440056 838.230408,67.368561 
	C837.164978,65.457397 835.143127,63.174824 837.703186,61.226048 
	C839.949768,59.515865 842.039612,61.110382 844.079956,62.998688 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M618.725769,142.209320 
	C621.510437,143.539627 624.488525,144.026825 625.523071,147.156082 
	C620.789551,149.348984 609.575500,144.398239 606.798096,138.805115 
	C611.322754,137.903961 614.712524,140.519165 618.725769,142.209320 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M819.334961,107.629669 
	C817.718750,105.466270 818.787598,103.947952 819.900085,102.463692 
	C821.071960,100.900230 819.815125,98.917969 821.095886,96.130005 
	C822.428284,101.972748 825.897034,106.285477 823.704529,111.907028 
	C821.445923,110.979485 820.894287,109.039124 819.334961,107.629669 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M809.940552,40.984425 
	C809.156433,41.237663 808.649963,41.487076 808.196350,41.412968 
	C804.667725,40.836544 802.998657,38.588341 803.501709,35.180672 
	C803.789612,33.230270 805.756958,32.404819 807.313110,33.569614 
	C809.594666,35.277355 813.322388,36.806168 809.940552,40.984425 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M801.976440,64.146736 
	C804.445618,60.269165 807.622131,58.688099 812.132385,59.577698 
	C809.293152,66.515846 805.852783,68.023010 801.976440,64.146736 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M241.724701,886.092651 
	C237.305603,888.884521 233.504990,885.012573 229.258270,884.693115 
	C233.474426,883.807861 237.520325,884.893005 241.724701,886.092651 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M860.421143,177.080093 
	C859.974792,178.755280 860.149902,180.424515 858.133179,181.167892 
	C856.408752,179.108154 856.476013,176.988907 858.453796,175.311478 
	C859.898193,174.086426 860.357849,175.458664 860.421143,177.080093 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
